
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as index5EA4kqBA8LalcxJVhUgr_IHBCqA_45ht8YApq7L5158CEMeta } from "/builds/go7seas/website/pages/index.vue?macro=true";
import { default as kontakthcR0gp9rI4Yp23HMGc9rof7iO1h5uWgSiM_45wr1TMxS8Meta } from "/builds/go7seas/website/pages/kontakt.vue?macro=true";
import { default as callbackFa2cPuIH2TfU_45flGYSjcH9VCOasAVvENKf4DibVh6LQMeta } from "/builds/go7seas/website/pages/callback.vue?macro=true";
import { default as _91_46_46_46slug_933bKUSnC3UYbjo9yp4vHfSPDsziu0AhesuIWgLYNQkvUMeta } from "/builds/go7seas/website/pages/[...slug].vue?macro=true";
import { default as einloggenyeFbLyHPIT_4548P9yy35QAhhAmu1s6sltV4Kd3wMJlzsMeta } from "/builds/go7seas/website/pages/einloggen.vue?macro=true";
import { default as _404I9bfNSHkKzW3sXPtlDgQA_Lw6_H8kSq_45ErS7bYzLQOcMeta } from "/builds/go7seas/website/pages/error/404.vue?macro=true";
import { default as _500A_456ut0qoW11mE1f87E7iQ3AOyXbkWVwlNKbyhhughrgMeta } from "/builds/go7seas/website/pages/error/500.vue?macro=true";
import { default as _503Feo3o78CW0KuN1AqQE6izoNfV_45Bs3bUQMyOTwh35eR8Meta } from "/builds/go7seas/website/pages/error/503.vue?macro=true";
import { default as reedereienY4TynBFjucy3LRURpTj93F8xKfxhUdrkvjqyUD2_452_45MMeta } from "/builds/go7seas/website/pages/reedereien.vue?macro=true";
import { default as agb4PJMOHXeJldfl3rz6HxZFJrtUGKbk1AwB_45ag5e9lgVIMeta } from "/builds/go7seas/website/pages/service/agb.vue?macro=true";
import { default as zielgebietecpNc4GbZiJ4hL6rz5oMETjMW4bneUvu9gp_zX24z4A4Meta } from "/builds/go7seas/website/pages/zielgebiete.vue?macro=true";
import { default as jobs_45quHad9F2yiaNq5yJMqs9t9eB_qIA4zXD3EnN6tS7UEMeta } from "/builds/go7seas/website/pages/service/jobs.vue?macro=true";
import { default as indexK29S3phmDHTcfeDUpwLDxof_cALcyq31G9BxSS9MtwcMeta } from "/builds/go7seas/website/pages/buchung/index.vue?macro=true";
import { default as indexhNDKkwo8ooyDAvoyWTrf8_4W_452Aq5YEczMZ4TUb7x1sMeta } from "/builds/go7seas/website/pages/magazin/index.vue?macro=true";
import { default as registrierungCLonXgX92XYkTTOM3wBtGedxwvISD0P43kV9PYVkzj4Meta } from "/builds/go7seas/website/pages/registrierung.vue?macro=true";
import { default as _91slug_93auJoNYciGnyXK8M7NAv83DyWQRyud87INHst1F3i_45XEMeta } from "/builds/go7seas/website/pages/magazin/[slug].vue?macro=true";
import { default as angebot_45anfrageHzpiCulkEhNCVZZiNgCWdw3DuP_X1CkpxhGQSr39L3wMeta } from "/builds/go7seas/website/pages/angebot-anfrage.vue?macro=true";
import { default as kabinenw8tfGSWFTJE1mI8KbqWdKErFJJfESniZ_AiAoOzhBwkMeta } from "/builds/go7seas/website/pages/auswahl/kabinen.vue?macro=true";
import { default as pruefen_45ERcI_45iwQFWL3t9mAr_wp03lOc8Nm4dhp6Bi0vQSbOoMeta } from "/builds/go7seas/website/pages/buchung/pruefen.vue?macro=true";
import { default as indexIO5sDYTHB0LDQV1H0_45bnthoCQ4DJ__453LcOk9RKsFUbgMeta } from "/builds/go7seas/website/pages/mygo7seas/index.vue?macro=true";
import { default as transferjU1zdPhddBTWmjguI0U_yhg4eTs90odw2Bhl06xcy3YMeta } from "/builds/go7seas/website/pages/auswahl/transfer.vue?macro=true";
import { default as _91_46_46_46suche_93QqexZ12dw6dzztPTGYZg69QUGIK5IgMCMXDz55LCZUoMeta } from "/builds/go7seas/website/pages/suche/[...suche].vue?macro=true";
import { default as kontaktFTDh1jNmH73GNs6C6IA_45hgR_45THnu7wh9e5sc09HtUyMMeta } from "/builds/go7seas/website/pages/mygo7seas/kontakt.vue?macro=true";
import { default as gutscheinOePHzovjkbKMFeZakbk7sZUaO_rRctb2k49H56H0E8MMeta } from "/builds/go7seas/website/pages/service/gutschein.vue?macro=true";
import { default as impressum3hLax_45tGHxoWfcRWJxP351AGp1u6At_SGBtIGNxUPZAMeta } from "/builds/go7seas/website/pages/service/impressum.vue?macro=true";
import { default as kategorien1ZMU2AEyb_RrPz_45ZwromgmcUzWfxYJWO0L_45PsTUQMKYMeta } from "/builds/go7seas/website/pages/auswahl/kategorien.vue?macro=true";
import { default as vergessenDqh_85Q5qy4Ku_btcaE8r67xp7_GqvraFZL5MJdEsX4Meta } from "/builds/go7seas/website/pages/passwort/vergessen.vue?macro=true";
import { default as zahlungsartgcabC61f1K07YbkKFwHUsHW9MGrLSYmhJqFk0CMKdhUMeta } from "/builds/go7seas/website/pages/buchung/zahlungsart.vue?macro=true";
import { default as indexObAXkJdBRzkpCYdmWbC4EKEopKE_jJrMBC7MkJCjCAsMeta } from "/builds/go7seas/website/pages/informationen/index.vue?macro=true";
import { default as anmeldenpzzOuXHiAsFz5x1nxBun_5iO953LaN3iOqbfbwLnCgsMeta } from "/builds/go7seas/website/pages/newsletter/anmelden.vue?macro=true";
import { default as datenschutz4eqk_45vXt7mVawHGqr42biTa3PswdnKem3WgvRUYaaBkMeta } from "/builds/go7seas/website/pages/service/datenschutz.vue?macro=true";
import { default as _91slug_93Di3HB2FpiDFOj_45ECLiGiOfDnMQWoQl2H1afUZJJgH1EMeta } from "/builds/go7seas/website/pages/informationen/[slug].vue?macro=true";
import { default as merkzettel52Su8Ja_45IklyPGmmgAaBFkdrNinN6_CIMto5NPk1MKYMeta } from "/builds/go7seas/website/pages/mygo7seas/merkzettel.vue?macro=true";
import { default as bewertungenohmjcxObYJi68hOZ7DJRRj2sNuFcSS1McstUwkjJif8Meta } from "/builds/go7seas/website/pages/mygo7seas/bewertungen.vue?macro=true";
import { default as indexRC9o90beBEm6fc60y_45MVZauLaPfQyNa10g0Hj8DrpfMMeta } from "/builds/go7seas/website/pages/mygo7seas/daten/index.vue?macro=true";
import { default as gutscheinkaufenwJOBOxskzqgqZ_JIqQIWW8I8ikIiZ2ntZ5yHYRKey_45wMeta } from "/builds/go7seas/website/pages/service/gutscheinkaufen.vue?macro=true";
import { default as _91_46_46_46slug_93M0GALg_H8V_45H5qEbsQ0y8FlJ859Vi3YUh_7Q_45S3AjXwMeta } from "/builds/go7seas/website/pages/newsletter/abmelden/[...slug].vue?macro=true";
import { default as profilhTz7MChVX_CxRUSkP7iyMcjX4X9mHPNA7ckek_1bNIoMeta } from "/builds/go7seas/website/pages/mygo7seas/daten/bearbeiten/profil.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/builds/go7seas/website/pages/index.vue")
  },
  {
    name: "kontakt",
    path: "/kontakt",
    component: () => import("/builds/go7seas/website/pages/kontakt.vue")
  },
  {
    name: "callback",
    path: "/callback",
    component: () => import("/builds/go7seas/website/pages/callback.vue")
  },
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/builds/go7seas/website/pages/[...slug].vue")
  },
  {
    name: "einloggen",
    path: "/einloggen",
    component: () => import("/builds/go7seas/website/pages/einloggen.vue")
  },
  {
    name: "error-404",
    path: "/error/404",
    meta: _404I9bfNSHkKzW3sXPtlDgQA_Lw6_H8kSq_45ErS7bYzLQOcMeta || {},
    component: () => import("/builds/go7seas/website/pages/error/404.vue")
  },
  {
    name: "error-500",
    path: "/error/500",
    meta: _500A_456ut0qoW11mE1f87E7iQ3AOyXbkWVwlNKbyhhughrgMeta || {},
    component: () => import("/builds/go7seas/website/pages/error/500.vue")
  },
  {
    name: "error-503",
    path: "/error/503",
    meta: _503Feo3o78CW0KuN1AqQE6izoNfV_45Bs3bUQMyOTwh35eR8Meta || {},
    component: () => import("/builds/go7seas/website/pages/error/503.vue")
  },
  {
    name: "reedereien",
    path: "/reedereien",
    component: () => import("/builds/go7seas/website/pages/reedereien.vue")
  },
  {
    name: "service-agb",
    path: "/service/agb",
    component: () => import("/builds/go7seas/website/pages/service/agb.vue")
  },
  {
    name: "zielgebiete",
    path: "/zielgebiete",
    component: () => import("/builds/go7seas/website/pages/zielgebiete.vue")
  },
  {
    name: "service-jobs",
    path: "/service/jobs",
    component: () => import("/builds/go7seas/website/pages/service/jobs.vue")
  },
  {
    name: "buchung",
    path: "/buchung",
    meta: indexK29S3phmDHTcfeDUpwLDxof_cALcyq31G9BxSS9MtwcMeta || {},
    component: () => import("/builds/go7seas/website/pages/buchung/index.vue")
  },
  {
    name: "magazin",
    path: "/magazin",
    component: () => import("/builds/go7seas/website/pages/magazin/index.vue")
  },
  {
    name: "registrierung",
    path: "/registrierung",
    component: () => import("/builds/go7seas/website/pages/registrierung.vue")
  },
  {
    name: "magazin-slug",
    path: "/magazin/:slug()",
    component: () => import("/builds/go7seas/website/pages/magazin/[slug].vue")
  },
  {
    name: "angebot-anfrage",
    path: "/angebot-anfrage",
    meta: angebot_45anfrageHzpiCulkEhNCVZZiNgCWdw3DuP_X1CkpxhGQSr39L3wMeta || {},
    component: () => import("/builds/go7seas/website/pages/angebot-anfrage.vue")
  },
  {
    name: "auswahl-kabinen",
    path: "/auswahl/kabinen",
    meta: kabinenw8tfGSWFTJE1mI8KbqWdKErFJJfESniZ_AiAoOzhBwkMeta || {},
    component: () => import("/builds/go7seas/website/pages/auswahl/kabinen.vue")
  },
  {
    name: "buchung-pruefen",
    path: "/buchung/pruefen",
    meta: pruefen_45ERcI_45iwQFWL3t9mAr_wp03lOc8Nm4dhp6Bi0vQSbOoMeta || {},
    component: () => import("/builds/go7seas/website/pages/buchung/pruefen.vue")
  },
  {
    name: "mygo7seas",
    path: "/mygo7seas",
    meta: indexIO5sDYTHB0LDQV1H0_45bnthoCQ4DJ__453LcOk9RKsFUbgMeta || {},
    component: () => import("/builds/go7seas/website/pages/mygo7seas/index.vue")
  },
  {
    name: "auswahl-transfer",
    path: "/auswahl/transfer",
    meta: transferjU1zdPhddBTWmjguI0U_yhg4eTs90odw2Bhl06xcy3YMeta || {},
    component: () => import("/builds/go7seas/website/pages/auswahl/transfer.vue")
  },
  {
    name: "suche-suche",
    path: "/suche/:suche(.*)*",
    component: () => import("/builds/go7seas/website/pages/suche/[...suche].vue")
  },
  {
    name: "mygo7seas-kontakt",
    path: "/mygo7seas/kontakt",
    meta: kontaktFTDh1jNmH73GNs6C6IA_45hgR_45THnu7wh9e5sc09HtUyMMeta || {},
    component: () => import("/builds/go7seas/website/pages/mygo7seas/kontakt.vue")
  },
  {
    name: "service-gutschein",
    path: "/service/gutschein",
    component: () => import("/builds/go7seas/website/pages/service/gutschein.vue")
  },
  {
    name: "service-impressum",
    path: "/service/impressum",
    component: () => import("/builds/go7seas/website/pages/service/impressum.vue")
  },
  {
    name: "auswahl-kategorien",
    path: "/auswahl/kategorien",
    meta: kategorien1ZMU2AEyb_RrPz_45ZwromgmcUzWfxYJWO0L_45PsTUQMKYMeta || {},
    component: () => import("/builds/go7seas/website/pages/auswahl/kategorien.vue")
  },
  {
    name: "passwort-vergessen",
    path: "/passwort/vergessen",
    component: () => import("/builds/go7seas/website/pages/passwort/vergessen.vue")
  },
  {
    name: "buchung-zahlungsart",
    path: "/buchung/zahlungsart",
    meta: zahlungsartgcabC61f1K07YbkKFwHUsHW9MGrLSYmhJqFk0CMKdhUMeta || {},
    component: () => import("/builds/go7seas/website/pages/buchung/zahlungsart.vue")
  },
  {
    name: "informationen",
    path: "/informationen",
    component: () => import("/builds/go7seas/website/pages/informationen/index.vue")
  },
  {
    name: "newsletter-anmelden",
    path: "/newsletter/anmelden",
    component: () => import("/builds/go7seas/website/pages/newsletter/anmelden.vue")
  },
  {
    name: "service-datenschutz",
    path: "/service/datenschutz",
    component: () => import("/builds/go7seas/website/pages/service/datenschutz.vue")
  },
  {
    name: "informationen-slug",
    path: "/informationen/:slug()",
    component: () => import("/builds/go7seas/website/pages/informationen/[slug].vue")
  },
  {
    name: "mygo7seas-merkzettel",
    path: "/mygo7seas/merkzettel",
    meta: merkzettel52Su8Ja_45IklyPGmmgAaBFkdrNinN6_CIMto5NPk1MKYMeta || {},
    component: () => import("/builds/go7seas/website/pages/mygo7seas/merkzettel.vue")
  },
  {
    name: "mygo7seas-bewertungen",
    path: "/mygo7seas/bewertungen",
    meta: bewertungenohmjcxObYJi68hOZ7DJRRj2sNuFcSS1McstUwkjJif8Meta || {},
    component: () => import("/builds/go7seas/website/pages/mygo7seas/bewertungen.vue")
  },
  {
    name: "mygo7seas-daten",
    path: "/mygo7seas/daten",
    meta: indexRC9o90beBEm6fc60y_45MVZauLaPfQyNa10g0Hj8DrpfMMeta || {},
    component: () => import("/builds/go7seas/website/pages/mygo7seas/daten/index.vue")
  },
  {
    name: "service-gutscheinkaufen",
    path: "/service/gutscheinkaufen",
    component: () => import("/builds/go7seas/website/pages/service/gutscheinkaufen.vue")
  },
  {
    name: "newsletter-abmelden-slug",
    path: "/newsletter/abmelden/:slug(.*)*",
    component: () => import("/builds/go7seas/website/pages/newsletter/abmelden/[...slug].vue")
  },
  {
    name: "mygo7seas-daten-bearbeiten-profil",
    path: "/mygo7seas/daten/bearbeiten/profil",
    meta: profilhTz7MChVX_CxRUSkP7iyMcjX4X9mHPNA7ckek_1bNIoMeta || {},
    component: () => import("/builds/go7seas/website/pages/mygo7seas/daten/bearbeiten/profil.vue")
  }
]