import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/builds/go7seas/website/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/builds/go7seas/website/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/builds/go7seas/website/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/builds/go7seas/website/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/builds/go7seas/website/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/builds/go7seas/website/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/builds/go7seas/website/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/builds/go7seas/website/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/builds/go7seas/website/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import api_jy9dy79pM_nYQuRKPrcfNg56p_gKkzw9SekUTZgAenc from "/builds/go7seas/website/plugins/api.ts";
import components_QVEdoEe9zI0c2PTzoT2LDjJr8LMeCgfbPmr22ZoPWqg from "/builds/go7seas/website/plugins/components.ts";
import router_S6yKjqoQ3Sb8o2lehB5PRjhy2uyi4DI_Ja_jhT1JS60 from "/builds/go7seas/website/plugins/router.ts";
import sentry_client_lpcrpmTLjShWJPDmp0fi6OOuaOcqqIWIe5I186xHGwE from "/builds/go7seas/website/plugins/sentry.client.ts";
import setTabToken_Ej_iAAieAChziWo7dLvuOJN_v1U12MA7Yg6qMJn6KJI from "/builds/go7seas/website/plugins/setTabToken.ts";
import toastification_CAgG_B3I6a6t1P3JbJ9gtdzfmN1A9pZclHut2RvU0Ng from "/builds/go7seas/website/plugins/toastification.js";
import vee_validate_tFsTD_xq7Lr2HBLXhmh4D79px5fI_UTbtS_7_2Rkn8g from "/builds/go7seas/website/plugins/vee-validate.ts";
import window_client_cGMwCYWTGFjuYxzbB0nU0iBpY9ThmYOBeXYULUOM7a8 from "/builds/go7seas/website/plugins/window.client.ts";
export default [
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  api_jy9dy79pM_nYQuRKPrcfNg56p_gKkzw9SekUTZgAenc,
  components_QVEdoEe9zI0c2PTzoT2LDjJr8LMeCgfbPmr22ZoPWqg,
  router_S6yKjqoQ3Sb8o2lehB5PRjhy2uyi4DI_Ja_jhT1JS60,
  sentry_client_lpcrpmTLjShWJPDmp0fi6OOuaOcqqIWIe5I186xHGwE,
  setTabToken_Ej_iAAieAChziWo7dLvuOJN_v1U12MA7Yg6qMJn6KJI,
  toastification_CAgG_B3I6a6t1P3JbJ9gtdzfmN1A9pZclHut2RvU0Ng,
  vee_validate_tFsTD_xq7Lr2HBLXhmh4D79px5fI_UTbtS_7_2Rkn8g,
  window_client_cGMwCYWTGFjuYxzbB0nU0iBpY9ThmYOBeXYULUOM7a8
]